<template>
  <div style="position:fixed !important;min-height: 30px; bottom: 5px; left: 5px; right: 5px;z-index: 10;width: calc(100vw - 10px)">

    <SearchControl v-if="!['Itinerary', 'ItineraryDetails', 'PointDetailsPublicTransports', 'PointDetailsOther', 'PointDetailsMicroMobility'].includes($route.name)" />

    <TopBarButtonBack
      v-else />

    <v-card :max-height="maxHeight" class="mt-2" v-if="toggleMainPanel" style="overflow-y: auto">
      <router-view :key="$route.path"/>
    </v-card>

  </div>
</template>

<script>

export default {

  name: "LeftPanelRootMobile",

  components: {
    TopBarButtonBack: () => import("@/components/Common/Controls/TopBarButtonBack"),
    MainPanel: () => import("@/views/MainPanel"),
    SearchControl: () => import("@/components/Common/Topbar"),
    Account: () => import("@/components/Common/Controls/AccountTopBar")
  },

  created() {
  },

  computed: {

    toggleMainPanel: {
      get() {
        return this.$store.getters['showLeftPanel'];
      },

      set(val) {
        this.$store.commit("setShowLeftPanel", val);
      }

    },

    maxHeight() {
      return "60vh";
      /*if (this.$route.name === 'Home')
        return "80vh";
      else if (this.$route.name === 'PointDetails')
        return "50vh";
      return "60vh";*/
    }
  }
}
</script>

<style scoped>

</style>